import * as React from 'react'
import TopLayout from './TopLayout'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import {linkResolver} from '../../src/utils/linkResolver'

export const wrapRootElement = ({element}) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME as string,
          linkResolver,
          componentResolver: componentResolverFromMap({}),
        },
      ]}
    >
      <TopLayout>{element}</TopLayout>
    </PrismicPreviewProvider>
  )
}
