import * as prismicH from '@prismicio/helpers'

export const linkResolver: prismicH.LinkResolverFunction<string> = doc => {
  if (doc.type === 'home') return `/`
  if (doc.type === 'article') return `/insights/${doc.tags[0]}/${doc.uid}`
  if (doc.type === 'about') return `/about`
  if (doc.type === 'data') return `/data`
  if (doc.type === 'analytics') return `/analytics`
  if (doc.type === 'solutions') return `/solutions`
  if (doc.type === 'contacts') return `/contact`
  if (doc.type === 'regulation') return `/regulation`
  if (doc.type === 'insights') return `/insights`
  if (doc.type === 'terms') return `/terms`
  if (doc.type === 'privacy') return `/privacy`
  if (doc.type === 'cookies') return `/cookies`
  return '/'
}
