exports.components = {
  "component---src-components-templates-article-page-tsx": () => import("./../../../src/components/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-components-templates-article-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-analytics-tsx": () => import("./../../../src/pages/analytics.tsx" /* webpackChunkName: "component---src-pages-analytics-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-insights-market-insights-tsx": () => import("./../../../src/pages/insights/market-insights.tsx" /* webpackChunkName: "component---src-pages-insights-market-insights-tsx" */),
  "component---src-pages-insights-press-releases-tsx": () => import("./../../../src/pages/insights/press-releases.tsx" /* webpackChunkName: "component---src-pages-insights-press-releases-tsx" */),
  "component---src-pages-insights-search-tsx": () => import("./../../../src/pages/insights/search.tsx" /* webpackChunkName: "component---src-pages-insights-search-tsx" */),
  "component---src-pages-insights-videos-tsx": () => import("./../../../src/pages/insights/videos.tsx" /* webpackChunkName: "component---src-pages-insights-videos-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-regulation-tsx": () => import("./../../../src/pages/regulation.tsx" /* webpackChunkName: "component---src-pages-regulation-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

