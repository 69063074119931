import {createTheme, responsiveFontSizes} from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 'bold',
      color: '#504478',
    },
    h2: {
      fontWeight: 'bold',
      color: '#504478',
    },
    h3: {
      fontWeight: 'bold',
      color: '#504478',
    },
    h4: {
      fontWeight: 'bold',
      color: '#504478',
    },
    h5: {
      fontWeight: 'bold',
      color: '#504478',
    },
    h6: {
      fontWeight: 'bold',
      color: '#504478',
    },
    button: {
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: '#504478',
      lineHeight: '1.5',
    },
    subtitle2: {
      fontWeight: 'bold',
      color: '#F95986',
    },
  },
  palette: {
    primary: {
      main: '#F95986',
      light: '#FEC2B4',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#ECF2F6',
      main: '#504478',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#7B819A',
      secondary: '#1B1B1B',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '2.3rem',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {size: 'small'},
          style: {
            padding: '0.4rem 2rem',
          },
        },
        {
          props: {size: 'medium'},
          style: {
            padding: '0.7rem 3rem',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 6,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& em': {
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#F95986',
          },
        },
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '30px!important',
          paddingLeft: 5,
          color: '#7B819A',
          background: '#fff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginLeft: 2,
          fontSize: '0.9rem',
          lineHeight: 1.7,
          color: '#7B819A',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: '0!important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: '9px!important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#7B819A',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          background: '#F95986',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '15px!important',
          width: 150,
          padding: 3,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#7B819A',
          borderRadius: '13px!important',
          padding: '0.5rem 1rem',
          '&:hover': {
            backgroundColor: '#E9E5F5',
            '& p': {
              fontWeight: 'bold',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: '-1.2rem',
          right: 0,
        },
      },
    },
  },
})
export default responsiveFontSizes(theme)
