import {useEffect} from 'react'

//Zoho Sales Iq Script:
const useZoho = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')

    const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "35b6d06c20be071f17b13bcd85bde9923ae86eb193aee6e76ffbc8dd9fb75ae01a2010ab7b6727677d37b27582c0e9c4", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

    script.appendChild(document.createTextNode(code))
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

export default useZoho
